import React from 'react';
import {Button, Container, Grid, Paper, Typography} from "@mui/material";
import { Alert, AlertTitle } from '@mui/material';

const FetchRoomsFail = () => {
    return (
        <Container sx={{p: 4, mt: 3, bgcolor: 'white'}}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    <AlertTitle>
                        <Grid container alignItems="center">
                            Failed to load Rooms
                        </Grid>
                    </AlertTitle>
                    There was an error while fetching the rooms. Please try again later.
                </Alert>
                <Grid container justifyContent="center">
                    <Grid item md={4} sx={{mt: 3}}>
                        <Button
                            style={{color: 'white'}}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => window.location.reload()}
                        >
                            <Typography>RETRY</Typography>
                        </Button>
                    </Grid>
                </Grid>
        </Container>
    );
}

export default FetchRoomsFail;
