import axios from 'axios'

// POST Create new house
export const addHouse = house => {
    return new Promise(((resolve, reject) => {
        axios.post('house/create', house)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// PUT Edid house entity
export const updateHouse = (houseId, house) => {
    return new Promise(((resolve, reject) => {
        axios.put(`/house/update/${houseId}`, house)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// GET Retrieve all houses
export const getAllHouses = () => {
    return new Promise(((resolve, reject) => {
        axios.get('user/getallhouses')
            .then(response => {
                resolve(response.data)
                return response.data
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    }))
}

// GET specific house by ID
export const getHouseEntity = houseId => {
    return new Promise(((resolve, reject) => {
        axios.get(`house/get/${houseId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// DELETE house entity
export const deleteHouseEntity = houseId => {
    return new Promise(((resolve, reject) => {
        axios.delete(`house/delete/${houseId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// GET house periodic consumptions
export const getHousePeriodicConsumptions = (period, houseId) => {
    return new Promise((resolve, reject) => {
        axios.get(`/data/${period}/${houseId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// GET house today 5min consumption
export const getHouseTodayConsumption = houseId => {
    return new Promise((resolve, reject) => {
        axios.get(`/data/daily/5min/${houseId}`)
            .then(response => {

                if (response.data.consumption === null || response.data.labels === null) reject('Not available data.')
                else resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// GET house latest metrics
export const getHouseLatestMetrics = houseId => {
    return new Promise((resolve, reject) => {
        axios.get(`/data/metrics/${houseId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}