import React, {useState, useEffect} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {styled, useTheme} from '@mui/material/styles';
import {useNavigate, useLocation, Outlet, Link} from "react-router-dom";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ScheduleIcon from '@mui/icons-material/Schedule';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExitToApp from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';


import FooterContent from "./FooterContent";
import MenuButton from "./MenuButton";
import {useRefreshHouses} from "../../hooks/useRefreshHouses";
import useHousesContext from "../../hooks/useHousesContext";

const drawerWidth = 240;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    background: theme.palette.fancy.main,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Footer = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    background: theme.palette.fancy.main,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: '100%',
        // marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Layout({children}) {
    const {keycloak, initialized} = useKeycloak();
    const {refreshHouses} = useRefreshHouses()
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const {housesUpd} = useHousesContext()

    useEffect(() => {
        keycloak.idToken && refreshHouses()
    }, [keycloak.idToken])

    const [username, setUsername] = useState('')

    useEffect(() => {
        if (keycloak.idToken) {
            keycloak.loadUserInfo()
                .then(response => {
                    setUsername(response.preferred_username)
                })
        }
    })

    const handleLogout = () => {
        keycloak.logout({
            redirectUri: `${window.location.origin}/`, // Redirect to the home page
        });
    };

    const [nestedScenarios, setNestedScenarios] = useState(true);
    const handleNestedScenarios = () => {
        setNestedScenarios(!nestedScenarios)
    }

    const [nestedRooms, setNestedRooms] = useState(false);
    const handleNestedRooms = () => {
        setNestedRooms(!nestedRooms)
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClickMenu = path => {
        navigate(path)
        setOpen(false)
    }

    const menuItems = keycloak?.idToken ? [
        {
            text: 'Homepage',
            icon: <DashboardIcon color="primary" />,
            path: "/",
            whiteIcon: <DashboardIcon sx={{ color: 'white' }} />
        },
        {
            text: 'Houses',
            icon: <OtherHousesIcon color="primary" />,
            path: "/houses",
            whiteIcon: <OtherHousesIcon sx={{ color: 'white' }} />
        },
        {
            text: 'Rooms',
            icon: <MeetingRoomIcon color="primary" />,
            whiteIcon: <MeetingRoomIcon sx={{ color: 'white' }} />,
            subItems: housesUpd,
            nestedHandler: nestedRooms,
            handleNested: handleNestedRooms
        },
        // {
        //     text: 'Scheduled scenarios',
        //     icon: <ScheduleIcon color="primary" />,
        //     whiteIcon: <ScheduleIcon sx={{ color: 'white' }} />,
        //     subItems: [
        //         {
        //             text: 'Air-Conditions',
        //             icon: <AcUnitIcon color="primary" />,
        //             path: "/airconditions/dashboard",
        //             whiteIcon: <AcUnitIcon sx={{ color: 'white' }} />
        //         },
        //         {
        //             text: 'Shellys',
        //             icon: <ConnectedTvIcon color="primary" />,
        //             path: `/shellys/dashboard/`,
        //             whiteIcon: <ConnectedTvIcon sx={{ color: 'white' }} />
        //         },
        //     ],
        //     nestedHandler: nestedScenarios,
        //     handleNested: handleNestedScenarios
        // },
    ] : [
        {
            text: 'Homepage',
            icon: <DashboardIcon color="primary" />,
            path: "/",
            whiteIcon: <DashboardIcon sx={{ color: 'white' }} />
        },
        {
            text: 'Sign Up',
            icon: <PersonAddIcon color="primary" />,
            path: "/signup",
            whiteIcon: <PersonAddIcon sx={{ color: 'white' }} />
        },
        {
            text: 'Sign In',
            icon: <AccountCircleIcon color="primary" />,
            whiteIcon: <AccountCircleIcon sx={{ color: 'white' }} />,
            handleNested: () => keycloak.login()  // Call keycloak login directly when clicking Sign In
        },
    ];

    return (
        <Box sx={{display: 'flex', minHeight: '100vh'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{mr: 2, ...(open && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                        Energy Home System
                    </Typography>
                    {username &&
                        <>
                            <Typography>Welcome, {username}</Typography>
                            <MenuButton subLinks={
                                [{
                                    title: 'Sign Out',
                                    icon: <LogoutOutlinedIcon color={'secondary'}/>,
                                    signout: () => handleLogout()
                                }]
                            }/>
                        </>}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {menuItems.map(menuItem => (
                        <div key={menuItem.text}>
                            <ListItemButton
                                onClick={menuItem.handleNested ? menuItem.handleNested : () => handleClickMenu(menuItem.path)}
                                className={location.pathname === menuItem.path ? 'menuItemActive' : ''}>
                                <ListItemIcon>
                                    {location.pathname === menuItem.path ? menuItem.whiteIcon : menuItem.icon}
                                </ListItemIcon>
                                <ListItemText
                                    sx={location.pathname === menuItem.path ? {color: 'white'} : {color: 'inherit'}}
                                    primary={menuItem.text}/>
                                {menuItem.subItems && (menuItem.nestedHandler ? <ExpandLessIcon/> :
                                    <ExpandMoreIcon/>)}
                            </ListItemButton>
                            {menuItem.subItems && menuItem.subItems.map(subItem => (
                                <Link key={subItem.text} to={subItem.path}
                                      onClick={() => setOpen(false)}
                                      style={{
                                          textDecoration: 'none',
                                          color: 'inherit'
                                      }}>
                                    <Collapse in={menuItem.nestedHandler} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{pl: 4}}
                                                            className={location.pathname === subItem.path ? 'menuItemActive' : ''}>
                                                <ListItemIcon>
                                                    {location.pathname === subItem.path ? subItem.whiteIcon : subItem.icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={location.pathname === subItem.path ? {color: 'white'} : {color: 'inherit'}}
                                                    primary={subItem.text}/>
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </Link>))}
                        </div>
                    ))}
                    <Divider/>

                    {keycloak.idToken && <ListItemButton
                        onClick={() => keycloak.logout()}>
                        <ListItemIcon>{<ExitToApp color="primary"/>}</ListItemIcon>
                        <ListItemText primary="Sign Out"></ListItemText>
                    </ListItemButton>}
                </List>

            </Drawer>
            <Main open={open} sx={{padding: 0, display: 'flex', flexDirection: 'column', background: '#F6F7F9'}}>
                <DrawerHeader/>
                {/*<div style={{marginBottom: '24px'}}><Outlet/></div>*/}
                <div><Outlet/></div>
                <Footer sx={{position: 'relative', mt: 'auto'}} open={open}><FooterContent/></Footer>
            </Main>
        </Box>
    );
}
