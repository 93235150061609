import React, {useState, useEffect} from 'react';
import {styled} from "@mui/material/styles";
import {green, red} from "@mui/material/colors";
import {broadlinkDelete, broadlinksGetAll} from "../../api_calls/api_calls_broadlinks";

import {
    Paper, TableContainer,
    Table, TableCell, Button,
    TableHead, TableRow, Typography,
    Divider, IconButton,
    Container, TableBody, Alert, AlertTitle
} from "@mui/material";

import {tableCellClasses} from "@mui/material/TableCell";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import BlockIcon from '@mui/icons-material/Block';

import Loading from "../layout/Loading";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.middle.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const BroadlinkListTable = ({
                               houseId,
                               setBroadlinkName,
                               setBroadlinkPort,
                               setBroadlinkIp,
                               setBroadlinkMac,
                               setBroadlinkType
                           }) => {
    const [loading, setLoading] = useState(false)
    const [broadlinks, setBroadlinks] = useState([])

    const fetchAllBroadlinks = () => {
        setLoading(true)
        broadlinksGetAll(houseId)
            .then(response => {
                setBroadlinks(response)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchAllBroadlinks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChooseBroadlink = (name, ip, mac, devtype, port) => {
        setBroadlinkName(name)
        setBroadlinkIp(ip)
        setBroadlinkMac(mac)
        setBroadlinkType(devtype)
        setBroadlinkPort(Number(port))
    }
    const handleDeleteBroadlink = id => {
        broadlinkDelete(id)
            .then(response => {
                fetchAllBroadlinks()

            })
            // TODO Add an alert
            .catch(error => {
                console.log(error)
                fetchAllBroadlinks()
            })
    }

    return (
        <Container maxWidth={'xl'} xs={12} sx={{maxWidth: '99vw'}}>
            <Paper sx={{p: 3, mt: 5}}>
                <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>Broadlinks list</Typography>
                <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>

                {broadlinks && broadlinks.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Name</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Ip</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Mac</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Dev Type</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Port</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Connected</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Choose</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align={'center'}>
                                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Remove</Typography>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {broadlinks.map(broadlink => (
                                <TableBody key={'broadlink.mac'}>
                                    <React.Fragment>
                                        <TableCell
                                            align={'center'}><Typography>{broadlink.name ? broadlink.name : '-'}</Typography></TableCell>
                                        <TableCell align={'center'}><Typography>{broadlink.ip ? broadlink.ip : '-'}</Typography></TableCell>
                                        <TableCell align={'center'}><Typography>{broadlink.mac ? broadlink.mac : '-'}</Typography></TableCell>
                                        <TableCell
                                            align={'center'}><Typography>{broadlink.devtype ? broadlink.devtype : '-'}</Typography></TableCell>
                                        <TableCell
                                            align={'center'}><Typography>{broadlink.port ? broadlink.port : '-'}</Typography></TableCell>
                                        <TableCell align={'center'}>
                                            {broadlink.connected ?
                                                <WifiIcon sx={{color: green[800], fontSize: '28px'}}/> :
                                                <WifiOffIcon sx={{color: red[800], fontSize: '28px'}}/>}
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {broadlink.choose ?
                                                <Button variant={'contained'} color={'success'}
                                                        onClick={() => handleChooseBroadlink(broadlink.name,
                                                            broadlink.ip,
                                                            broadlink.mac,
                                                            broadlink.devtype,
                                                            broadlink.port)}>Connect</Button> :
                                                <BlockIcon sx={{color: red[800], fontSize: '28px'}}/>}
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            <IconButton onClick={() => handleDeleteBroadlink(broadlink.id)}>
                                                <DeleteOutlineIcon sx={{color: red[800], fontSize: '28px'}}/>
                                            </IconButton>
                                        </TableCell>
                                    </React.Fragment>
                                </TableBody>
                            ))}
                        </Table>
                    </TableContainer>
                }
                {loading && <Container sx={{py: 2}}><Loading/></Container>}

                {(!broadlinks || broadlinks.length < 1) && !loading && <Alert severity="error">
                    <AlertTitle>No available broadlinks!</AlertTitle>
                    There are currently no broadlinks that could be connected.
                </Alert>}
            </Paper>
        </Container>
    );
}


export default BroadlinkListTable;
