import React from 'react';
import {addShellyToRoom, createNewAcController} from "../../api_calls/api_calls_rooms";
import Typography from "@mui/material/Typography";
import {Alert, AlertTitle, Divider, Grid, Tooltip, Container} from "@mui/material";
import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import ListItemText from "@mui/material/ListItemText";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";


import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const ControllersList = ({broadlinks, shellys, roomId, setOpenModal, setSuccess, setFailure}) => {
    const createAcController = (name, id) => {
        let sensor = {
            brandType: 'brandType',
            broadlinkId: id,
            name: name,
            roomId: roomId
        }

        createNewAcController(sensor)
            .then(response => {
                setOpenModal(false)
                setSuccess(true)
            })
            .catch(error => {
                console.log(error)
                setFailure(true)
            })
    }

    const addShelly = (shellyId) => {
        addShellyToRoom(roomId, shellyId)
            .then(response => {
                setOpenModal(false)
                setSuccess(true)
            })
            .catch(error => {
                console.log(error)
                setFailure(true)
            })
    }

    return (
        <Card sx={{p: 0, width: '100%'}}>
            <CardHeader className={'fancyBackground'} title={
                <>
                    <Typography variant="h4" sx={{color: 'white', pb: 0}}>List of controllers</Typography>
                    <Divider sx={{backgroundColor: 'white', height: '2px', width: '100%', my: 1}}/>
                </>}
            />
            <CardContent>
                <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    width: '100%',
                    maxWidth: 500,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    maxHeight: 300,
                    px: 0,
                }}
                      subheader={<li/>}>
                    {broadlinks && broadlinks.map(appliance =>
                        <React.Fragment key={appliance.name}>
                            <ListItem disablePadding>
                                <Grid container>
                                    <Grid item xs={10} sx={{display: 'flex', flexGrow: '1'}}>
                                        <ListItemIcon>
                                            <ElectricalServicesIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={appliance.name}
                                                      sx={{display: 'flex'}}/>
                                    </Grid>
                                    <Grid item xs={2} sx={{display: 'flex'}}>
                                        <Tooltip title={'Add'} placement={'top'}>
                                            <ListItemButton alignItems={'center'} sx={{justifyContent: 'center'}}
                                                            disableGutters>
                                                <ListItemIcon sx={{justifyContent: 'center'}}
                                                              onClick={() => createAcController(appliance.name, appliance.id)}>
                                                    <SettingsRemoteIcon/>
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </React.Fragment>)}
                    {shellys && shellys.map(appliance =>
                        <React.Fragment key={appliance.name}>
                            <ListItem disablePadding>
                                <Grid container>
                                    <Grid item xs={10} sx={{display: 'flex', flexGrow: '1'}}>
                                        <ListItemIcon>
                                            <ElectricalServicesIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={appliance.name}
                                                      sx={{display: 'flex'}}/>
                                    </Grid>
                                    <Grid item xs={2} sx={{display: 'flex'}}>
                                        <Tooltip title={'Add'} placement={'top'}>
                                            <ListItemButton alignItems={'center'} sx={{justifyContent: 'center'}}
                                                            disableGutters>
                                                <ListItemIcon sx={{justifyContent: 'center'}}
                                                              onClick={() => addShelly(appliance.id)}>
                                                    <SettingsRemoteIcon/>
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </React.Fragment>)}
                </List>
            </CardContent>
            {broadlinks.length === 0 && shellys.length === 0 &&
                <Container maxWidth={'xl'}>
                    <Alert severity="error" sx={{pt: 2}}>
                        <AlertTitle>No available controllers currently!</AlertTitle>
                    </Alert>
                </Container>
            }
        </Card>
    );
}

export default ControllersList;