import React, {useState, useEffect} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {useNavigate} from 'react-router-dom';
import {addRoom} from '../../api_calls/api_calls_rooms';
import {getAllHouses} from '../../api_calls/api_calls_houses';

import LoadingFullPage from '../../components/layout/LoadingFullPage';

import {
    Button,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Stack,
    Snackbar,
    Box,
    Alert,
} from '@mui/material';

const AddRoom = () => {
    useEffect(() => {
        document.title = 'Add Room | Energy Home System';
    }, []);

    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            } else {
                setAllowed(true);
            }
        }
    }, [keycloak, initialized]);

    const navigate = useNavigate();

    // Form state
    const [formValues, setFormValues] = useState({
        name: '',
        relatedHome: '',
        roomUsage: '',
        area: '',
        windows: '',
        hasAirCondition: false,
        airConditionUsage: '',
        airConditionBTU: '',
    });

    // Error state
    const [formErrors, setFormErrors] = useState({
        name: false,
        relatedHome: false,
        roomUsage: false,
        area: false,
        windows: false,
        airConditionUsage: false,
        airConditionBTU: false,
    });

    // Submission state
    const [hasSubmitted, setHasSubmitted] = useState(false);

    // Other component states
    const [houses, setHouses] = useState([]);
    const [zeroHouses, setZeroHouses] = useState(false);
    const [backDrop, setBackDrop] = useState(false);

    // Notification state
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleNotificationClose = () => {
        setNotification({...notification, open: false});
    };

    useEffect(() => {
        if (initialized) {
            getAllHouses()
                .then(response => {
                    setHouses(response || []);
                    setZeroHouses(response === null);
                })
                .catch(() => {
                    setNotification({
                        open: true,
                        message: 'Something went wrong while fetching houses! Please try again.',
                        severity: 'error'
                    });
                });
        }
    }, [initialized]);

    const validateForm = (values) => {
        const errors = {};
        if (!values.name) errors.name = true;
        if (!values.relatedHome) errors.relatedHome = true;
        if (!values.roomUsage) errors.roomUsage = true;
        if (!values.area || isNaN(parseInt(values.area))) errors.area = true;
        if (!values.windows || isNaN(parseInt(values.windows))) errors.windows = true;
        if (values.hasAirCondition) {
            if (!values.airConditionUsage) errors.airConditionUsage = true;
            if (!values.airConditionBTU) errors.airConditionBTU = true;
        }
        return errors;
    };

    useEffect(() => {
        if (hasSubmitted) {
            setFormErrors(validateForm(formValues));
        }
    }, [formValues, hasSubmitted]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setHasSubmitted(true);

        const errors = validateForm(formValues);
        if (Object.keys(errors).length === 0) {
            setBackDrop(true);

            const room = {
                name: formValues.name,
                houses_table_id: formValues.relatedHome,
                roomUsage: formValues.roomUsage,
                area: parseInt(formValues.area),
                windows: parseInt(formValues.windows),
                hasAirCondition: formValues.hasAirCondition ? 'Yes' : 'No',
                airConditionUsage: formValues.hasAirCondition ? formValues.airConditionUsage : '',
                airConditionBTU: formValues.hasAirCondition ? formValues.airConditionBTU : '',
            };

            addRoom(room)
                .then(() => {
                    setBackDrop(false);
                    setNotification({
                        open: true,
                        message: 'The room has been successfully added!',
                        severity: 'success'
                    });
                    setTimeout(() => {
                        navigate('/rooms');
                    }, 3000);
                })
                .catch(() => {
                    setBackDrop(false);
                    setNotification({
                        open: true,
                        message: 'Something went wrong! Please try again.',
                        severity: 'error'
                    });
                });
        } else {
            setFormErrors(errors);
        }
    };

    return (
        <>
            {allowed && <>
                {!zeroHouses && (
                    <Container>
                        <Paper sx={{p: 3, mt: 5}}>
                            <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>Add Room</Typography>
                            <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>
                            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="outlined-basic"
                                            label="Room name"
                                            variant="outlined"
                                            placeholder="Insert room name"
                                            error={formErrors.name}
                                            onChange={(e) => setFormValues({...formValues, name: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl required fullWidth error={formErrors.relatedHome}>
                                            <InputLabel id="demo-simple-select-label">House it belongs to</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formValues.relatedHome}
                                                label="House it belongs to"
                                                onChange={(e) => setFormValues({
                                                    ...formValues,
                                                    relatedHome: e.target.value
                                                })}
                                            >
                                                {houses.map((house) => (
                                                    <MenuItem key={house.id} value={house.id}>{house.address}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl required fullWidth error={formErrors.roomUsage}>
                                            <InputLabel id="demo-simple-select-label">Room usage</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formValues.roomUsage}
                                                label="Room usage"
                                                onChange={(e) => setFormValues({
                                                    ...formValues,
                                                    roomUsage: e.target.value
                                                })}
                                            >
                                                <MenuItem value='livingroom'>Living Room</MenuItem>
                                                <MenuItem value='bedroom'>Bedroom</MenuItem>
                                                <MenuItem value='office'>Office</MenuItem>
                                                <MenuItem value='other'>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="outlined-basic"
                                            label="Square meters"
                                            variant="outlined"
                                            error={formErrors.area}
                                            placeholder="Insert room's area"
                                            type="number"
                                            InputProps={{inputProps: {min: 0}, fullWidth: true}}
                                            onChange={(e) => setFormValues({...formValues, area: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="outlined-basic"
                                            label="Number of windows"
                                            variant="outlined"
                                            error={formErrors.windows}
                                            placeholder="Insert number of windows"
                                            type="number"
                                            InputProps={{inputProps: {min: 0}, fullWidth: true}}
                                            onChange={(e) => setFormValues({...formValues, windows: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel
                                            label="Air-Condition in the room"
                                            control={<Checkbox checked={formValues.hasAirCondition}
                                                               onChange={(e) => setFormValues({
                                                                   ...formValues,
                                                                   hasAirCondition: e.target.checked
                                                               })}/>}
                                        />
                                    </Grid>
                                    {formValues.hasAirCondition && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <FormControl required fullWidth error={formErrors.airConditionUsage}>
                                                    <InputLabel id="demo-simple-select-label">Air-Condition's
                                                        usage</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={formValues.airConditionUsage}
                                                        label="Air-Condition's usage"
                                                        onChange={(e) => setFormValues({
                                                            ...formValues,
                                                            airConditionUsage: e.target.value
                                                        })}
                                                    >
                                                        <MenuItem value='often'>Often</MenuItem>
                                                        <MenuItem value='sometimes'>Sometimes</MenuItem>
                                                        <MenuItem value='rarely'>Rarely</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="outlined-basic"
                                                    label="Air-Condition's BTUs"
                                                    variant="outlined"
                                                    error={formErrors.airConditionBTU}
                                                    placeholder="Insert Air-Condition's BTUs"
                                                    type="number"
                                                    InputProps={{inputProps: {min: 0}, fullWidth: true}}
                                                    onChange={(e) => setFormValues({
                                                        ...formValues,
                                                        airConditionBTU: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Box sx={{display: 'flex', mt: 3}}>
                                    <Button variant="contained" fullWidth sx={{backgroundColor: 'middle.main'}} type="submit">
                                        Add Room
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                    </Container>
                )}
                {zeroHouses && (
                    <Stack sx={{height: '100vh'}} alignItems="center" justifyContent="center">
                        <Typography variant="h3">You don't have any houses yet.</Typography>
                        <Typography variant="h4">Please add a house first.</Typography>
                        <Button
                            variant="contained"
                            sx={{backgroundColor: 'middle.main', mt: 3}}
                            onClick={() => navigate('/add-house')}
                        >
                            Add House
                        </Button>
                    </Stack>
                )}
                <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleNotificationClose}>
                    <Alert onClose={handleNotificationClose} variant={"filled"} severity={notification.severity}
                           sx={{width: '100%'}}>
                        {notification.message}
                    </Alert>
                </Snackbar>
            </>}
            <LoadingFullPage backDrop={backDrop}/>
        </>
    );
};

export default AddRoom;
