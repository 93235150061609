import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';import {useSwitch} from "@mui/base";

import {
    Avatar, Box, Button, Card,
    CardContent, CardHeader,
    Grid, IconButton,
    Stack, TextField, Typography
} from "@mui/material";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";

import AcUnitIcon from "@mui/icons-material/AcUnit";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {green, red} from "@mui/material/colors";
import Loading from "../layout/Loading";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 16 16"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793V8.866l-3.4 1.963-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495L7 8 3.6 6.037l-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849L7.5 7.134V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 1 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v3.927l3.4-1.963.496-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l3.4 1.963 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-3.4-1.963v3.927l1.353 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const AirConditionController = ({
                                    appliance,
                                    handleExecutionOnOff,
                                    isHeat, setIsHeat,
                                    increaseTemperature,
                                    decreaseTemperature,
                                    tempField,
                                    temperature,
                                    valueON,
                                    handleChangeON,
                                    valueOFF,
                                    handleChangeOFF,
                                    handleExecutionScheduling,
                                    handleExecutionTempHeat,
                                    schedulingStartLoading,
                                    schedulingEndLoading,
                                }) => {
    return (
        <Card elevation={2}
              sx={{
                  height: '100%', display: 'flex', flexDirection: 'column',
                  justifyContent: 'space-between', maxHeight: '600px'
              }}>
            <CardHeader className={'fancyBackground'}
                        avatar={<Avatar sx={{bgcolor: '#fff'}}><AcUnitIcon color={'primary'}/></Avatar>}
                        title={<Typography sx={{color: 'white'}}
                                           variant={'subtitle1'}>{appliance.name}</Typography>}
                        subheader={<Typography sx={{color: 'white'}}
                                               variant={'subtitle2'}>{appliance.roomname +
                            ', ' + appliance.address}</Typography>}
            />
            <CardContent
                sx={{px: 0, paddingBottom: '0 !important', height: '100%', display: 'flex', flexDirection: 'column'}}>
                {/*<Grid container justifyContent={'center'}>*/}
                <Grid item display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}
                      xs={12}>
                    <IconButton aria-label="delete" onClick={handleExecutionOnOff}>
                        <PowerSettingsNewIcon
                            sx={{
                                justifyContent: 'center',
                                fontSize: '8rem',
                                color: red[500],
                                '&:hover': {color: red[600]}
                            }}/>
                    </IconButton>
                </Grid>
                <Box sx={{width: '100%', backgroundColor: '#f2f2f2'}}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={8}>
                            <Grid item display={'flex'} justifyContent={'center'} xs={12} mt={5}>
                                <div onClick={() => setIsHeat(!isHeat)}>
                                    {isHeat ? <MaterialUISwitch/> : <MaterialUISwitch/>}
                                </div>
                            </Grid>
                            <Grid item display={'flex'} justifyContent={'center'} xs={12}>
                                <IconButton onClick={decreaseTemperature}><ChevronLeftIcon
                                    sx={{fontSize: 60, my: 'auto', color: 'black'}}/></IconButton>
                                <span ref={tempField} style={{display: 'flex'}} id="temperature">
                                                        <Typography fontSize={60}>{temperature}</Typography>
                                                        <Typography fontSize={40} mb={'auto'}>°C</Typography>
                                                    </span>
                                <IconButton onClick={increaseTemperature}><ChevronRightIcon
                                    sx={{fontSize: 60, my: 'auto', color: 'black'}}/></IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{my: 'auto'}} display={'flex'}
                              justifyContent={'center'} alignItems={'center'}>
                            <Button variant="contained" size={'large'} sx={{
                                backgroundColor: green[500],
                                '&:hover': {backgroundColor: green[700]}
                            }}
                                    onClick={handleExecutionTempHeat}>OPEN</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{width: '100%', backgroundColor: '#fafafa', borderTop: '2px solid #f4f4f4'}}
                     display={'flex'} flexDirection={'column'}
                     justifyContent={'center'} alignItems={'center'} py={3}>
                    <Stack spacing={2} direction="row">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Turn on at"
                                value={valueON}
                                onChange={handleChangeON}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <Button disabled={!valueON}
                                variant="contained" color="middle" onClick={() => handleExecutionScheduling('ON')}>
                            {schedulingStartLoading ? <Loading color={'white'}/> :
                                <Typography color={'white'}>SET</Typography>}
                        </Button>
                    </Stack>
                    <Stack spacing={2} direction="row" mt={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Turn off at"
                                value={valueOFF}
                                onChange={handleChangeOFF}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <Button variant="contained" disabled={!valueOFF} color="middle"
                                onClick={() => handleExecutionScheduling('OFF')}>
                            {schedulingEndLoading ? <Loading color={'white'}/> :
                                <Typography color={'white'}>SET</Typography>}</Button>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    );
}

export default AirConditionController;