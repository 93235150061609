import React from 'react';
import ReactECharts from 'echarts-for-react';
import {Grid, Typography, Paper, Box} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function HouseMonitoringAdditional() {
    // Realistic placeholder values for demonstration
    const todayConsumption = 25;   // Today's consumption in kWh
    const weeklyConsumption = 180; // Weekly consumption in kWh

    // Realistic averages for the charts
    const todayAverage = 30;   // Average daily consumption in kWh
    const weekAverage = 210;   // Average weekly consumption in kWh

    // Percentage calculations
    const todayPercentage = Math.min(100, ((todayConsumption || 0) / todayAverage) * 100);
    const weeklyPercentage = Math.min(100, ((weeklyConsumption || 0) / weekAverage) * 100);

    // Gradient colors for the doughnut charts
    const gradientColor = {
        measured: {
            type: 'linear',
            x: 0, y: 0, x2: 1, y2: 1,
            colorStops: [
                {offset: 0, color: '#00ABE1'}, // Light blue
                {offset: 1, color: '#161F6D'}  // Dark blue
            ]
        },
        remaining: '#E0E0E0'
    };

    // Doughnut Chart: Today's Consumption
    const todayDoughnutOptions = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                if (params.name === 'Consumption') {
                    return `Consumption today vs Average Daily Consumption: ${params.value}%`;
                }
                return ''; // Hides tooltip for grey section
            }
        },
        series: [
            {
                name: "Today's Consumption",
                type: 'pie',
                radius: ['50%', '70%'],
                label: {show: false},
                labelLine: {show: false},
                data: [
                    {
                        value: todayPercentage.toFixed(2),
                        name: 'Consumption',
                        itemStyle: {color: gradientColor.measured}
                    },
                    {
                        value: (100 - todayPercentage).toFixed(2),
                        name: 'Daily Average',
                        itemStyle: {color: gradientColor.remaining}
                    }
                ]
            }
        ]
    };

    // Doughnut Chart: Weekly Consumption
    const weeklyDoughnutOptions = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                if (params.name === 'Consumption') {
                    return `Consumption this week vs Average Weekly Consumption: ${params.value}%`;
                }
                return ''; // Hides tooltip for grey section
            }
        },
        series: [
            {
                name: "Weekly Consumption",
                type: 'pie',
                radius: ['50%', '70%'],
                label: {show: false},
                labelLine: {show: false},
                data: [
                    {
                        value: weeklyPercentage.toFixed(2),
                        name: 'Consumption',
                        itemStyle: {color: gradientColor.measured}
                    },
                    {
                        value: (100 - weeklyPercentage).toFixed(2),
                        name: 'Weekly Average',
                        itemStyle: {color: gradientColor.remaining}
                    }
                ]
            }
        ]
    };

    return (
        <Paper
            elevation={0}
            style={{
                padding: '20px',
                borderRadius: '25px',
                backgroundColor: 'white',
                height: '100%',
            }}
        >
            {/* General Title */}
            <Typography
                variant="h5"
                align="center"
                sx={{fontWeight: 'bold'}}
                className={'fancyText'}
            >
                Consumption Overview
            </Typography>

            <Grid container spacing={2} alignItems="center" style={{height: '100%'}}>
                {/* Today's Consumption Doughnut Chart */}
                <Grid item xs={12} md={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <ReactECharts option={todayDoughnutOptions} style={{height: '250px', width: '100%'}}/>
                        <Typography align="center" variant="body1">
                            <strong>{todayConsumption} kWh</strong> (<strong>{todayPercentage.toFixed(1)}%</strong> of
                            average)
                        </Typography>
                        {/* Subtitle with Info Icon */}
                        <Box display="flex" alignItems="center" gap="5px" mt={1}>
                            <InfoOutlinedIcon sx={{color: '#00ABE1'}}/>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                                sx={{fontSize: '0.75rem', lineHeight: '1.2', mt: 0.5}}
                            >
                                Consumption today compared to last month's average daily consumption
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                {/* Weekly Consumption Doughnut Chart */}
                <Grid item xs={12} md={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <ReactECharts option={weeklyDoughnutOptions} style={{height: '250px', width: '100%'}}/>
                        <Typography align="center" variant="body1">
                            <strong>{weeklyConsumption} kWh</strong> (<strong>{weeklyPercentage.toFixed(1)}%</strong> of
                            average)
                        </Typography>
                        {/* Subtitle with Info Icon */}
                        <Box display="flex" alignItems="center" gap="5px" mt={1}>
                            <InfoOutlinedIcon sx={{color: '#00ABE1'}}/>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                                sx={{fontSize: '0.75rem', lineHeight: '1.2', mt: 0.5}}
                            >
                                Consumption this week compared to last week's average total consumption
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default HouseMonitoringAdditional;
