import React, {useState, useEffect} from 'react';
import  {shellyGetAllScenarios, shellyDeleteScheduledScenario} from "../../api_calls/api_calls_appliance";

import {styled} from "@mui/material/styles";
import {tableCellClasses} from "@mui/material/TableCell";
import {red} from "@mui/material/colors";
import {
    Container, Divider, Paper, Typography,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Tooltip, Alert, AlertTitle
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import PageTitle from "../../components/layout/PageTitle";
import LoadingFullPage from "../../components/layout/LoadingFullPage";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.middle.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ShellysDashboard = () => {
    const [scenarios, setScenarios] = useState([])

    // Variable for backdrop and alerts
    const [backDrop, setBackDrop] = useState(false)

    const getScenarios = () => {
        setBackDrop(true)
        shellyGetAllScenarios()
            .then(response => {
                response === null ? setScenarios([]) : setScenarios(response)
                setBackDrop(false)
            })
            .catch(error => {
                console.log(error)
                setBackDrop(false)
            })
    }

    const handleScenarioDelete = tag => {
        shellyDeleteScheduledScenario(tag)
            .then(response => {
                getScenarios()
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getScenarios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <PageTitle title="All Shellys' Scheduled Scenarios"/>
            <Container maxWidth={'xl'} xs={12} sx={{maxWidth: '99vw'}}>
                <Paper sx={{p: 3, mt: 5}}>
                    <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>
                        List of Shellys' scheduled scenarios
                    </Typography>
                    <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>
                    {scenarios.length > 0 && <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align={'center'}><Typography
                                        variant={'body1'}
                                        sx={{fontWeight: 'bold'}}>Function</Typography></StyledTableCell>

                                    <StyledTableCell align={'center'}><Typography
                                        variant={'body1'}
                                        sx={{fontWeight: 'bold'}}>Day(s)</Typography></StyledTableCell>

                                    <StyledTableCell align={'center'}><Typography
                                        variant={'body1'} sx={{fontWeight: 'bold'}}>Turn on
                                        at</Typography></StyledTableCell>

                                    <StyledTableCell align={'center'}><Typography
                                        variant={'body1'} sx={{fontWeight: 'bold'}}>Turn off
                                        at</Typography></StyledTableCell>

                                    <StyledTableCell align={'center'}><Typography
                                        variant={'body1'}
                                        sx={{fontWeight: 'bold'}}>Delete</Typography></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {scenarios.map(scenario => (
                                <TableBody key={scenario.tag}>
                                    <React.Fragment>
                                        <TableCell
                                            align={'center'}><Typography>{scenario.isheat === '1' ? 'Heating' : 'Cooling'}</Typography></TableCell>
                                        <TableCell align={'center'}><Typography>{scenario.days.join(', ')}</Typography></TableCell>
                                        <TableCell
                                            align={'center'}><Typography>{scenario.starttime}</Typography></TableCell>
                                        <TableCell
                                            align={'center'}><Typography>{scenario.endtime}</Typography></TableCell>
                                        <TableCell align={'center'}>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => handleScenarioDelete(scenario.tag)}>
                                                    <DeleteOutlineIcon sx={{color: red[500], fontSize: '25px'}}/>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </React.Fragment>
                                </TableBody>
                            ))}
                        </Table>
                    </TableContainer>}
                    {scenarios.length < 1 &&
                        <Alert severity="error">
                            <AlertTitle>No scheduled scenarios!</AlertTitle>
                            Schedule your devices and come back to see the list of scheduled scenarios!
                        </Alert>}
                    <LoadingFullPage backDrop={backDrop} setBackDrop={setBackDrop}/>
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default ShellysDashboard;