import React from 'react';
import {useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {Fab, Tooltip} from "@mui/material";

const FloatingActionButton = ({link, tooltip}) => {
    const navigate = useNavigate()

    return (
        <Tooltip title={tooltip}>
            <Fab sx={{position: 'fixed', bottom: '80px', right: '20px'}} color="secondary" aria-label="add">
                <AddIcon onClick={() => navigate(link)} sx={{color: 'white'}}/>
            </Fab>
        </Tooltip>
    );
}

export default FloatingActionButton;