import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {range} from "../../ulitities";
import {styled} from "@mui/material/styles";
import {tableCellClasses} from "@mui/material/TableCell";
import {red} from "@mui/material/colors";
import {
    Box, Button, Typography, Alert, Grid, TableContainer,
    Select, FormControl, MenuItem, Stack, Container,
    InputLabel, OutlinedInput, Checkbox, ListItemText,
    TextField, Snackbar, AccordionSummary, AccordionDetails,
    AlertTitle, Accordion, TableCell, Table, TableHead, TableRow, TableBody, Tooltip, Paper,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';

import {acEntityDeleteScheduledScenario, acScheduling} from "../../api_calls/api_calls_appliance";
import {acControllerGetEntity} from "../../api_calls/api_calls_broadlinks";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import LoadingFullPage from "../layout/LoadingFullPage";
import Loading from "../layout/Loading";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const dayList = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

const CustomAlert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.middle.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const temperatureList = range(17, 30)

const AirConditionScheduling = ({scenarios, scenariosLoading, getACScenarios}) => {
    let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE3MTY1NTA2OTIsInVzZXJfaWQiOiIxZjZiNWZjNy1jNmEzLTQyMzctOGQzZS0yZjc3MzIxNTQyNjYifQ.PdkP_Tjyhy-vYSmwZSF96mMoloUtkOAiiCzeqLGbZ4E'
    const {id} = useParams();

    useEffect(() => {
        acControllerGetEntity(id)
            .then(response => {
                document.title = `${response.name} Learning Mode | Energy Home System`
            })
            .catch(error => console.log(error))

        getACScenarios()
    }, [])

    const handleScenarioDelete = tag => {
        acEntityDeleteScheduledScenario(tag)
            .then(response => {
                getACScenarios()
            })
            .catch(error => {
                console.log(error)
            })
    }

    // Form fields to be sent
    const [days, setDays] = useState([]);
    const [airConditionUsage, setAirConditionUsage] = useState('')
    const [temperature, setTemperature] = useState('')
    const [valueON, setValueON] = useState(null);
    const [valueOFF, setValueOFF] = useState(null);

    // Error handlers
    const [daysError, setDaysError] = useState(false);
    const [airConditionUsageError, setAirConditionUsageError] = useState(false)
    const [temperatureError, setTemperatureError] = useState(false)
    const [valueONError, setValueONError] = useState(false);
    const [valueOFFError, setValueOFFError] = useState(false);

    // Variable for backdrop
    const [backDrop, setBackDrop] = useState(false)

    // Variable for snackbar
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)

    const handleDays = (event) => {
        const {
            target: {value},
        } = event;
        setDays(
            // On autofill we get the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeON = newValue => setValueON(newValue)

    const handleChangeOFF = newValue => setValueOFF(newValue)

    const handleSubmit = e => {
        e.preventDefault()

        // Error handling
        days.length === 0 ? setDaysError(true) : setDaysError(false)
        airConditionUsage === '' ? setAirConditionUsageError(true) : setAirConditionUsageError(false)
        temperature === '' ? setTemperatureError(true) : setTemperatureError(false)
        if (valueON === null && valueOFF === null) {
            setValueONError(true)
            setValueOFFError(true)
        } else {
            setValueONError(false)
            setValueOFFError(false)
        }

        // Request to API
        if (days.length > 0 && airConditionUsage !== '' && temperature !== '' && (valueON !== null || valueOFF !== null)) {
            setBackDrop(true)
            let payload = {
                days: days,
                descr: 'schedule',
                endtime: valueOFF,
                id,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                isHeat: airConditionUsage === 'heat' ? '1' : '0',
                months: '',
                name: '',
                startTime: valueON,
                temp: temperature.toString(),
                typeofdevice: 'ac',
                typeofjob: ''
            }

            console.log('Sent to scheduling ("/job/create")', payload)

            acScheduling(payload)
                .then(response => {
                    setBackDrop(false)
                    setSuccess(true)
                    setDays([])
                    setAirConditionUsage('')
                    setTemperature('')
                    setValueON(null)
                    setValueOFF(null)
                    getACScenarios()
                    console.log('Response from API', response)
                })
                .catch(error => {
                    setBackDrop(false)
                    setFailure(true)
                })
        }
    }

    return (
        <Grid sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <Accordion sx={{borderRadius: '5px'}} disableGutters defaultExpanded>
                <AccordionSummary className={'fancyBackground'}
                                  sx={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px'}}
                                  expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                >
                    <Box>
                        <Typography variant="h6" sx={{color: '#fff'}}>
                            Scheduled scenarios
                        </Typography>
                        <Typography variant={'subtitle2'} sx={{color: '#fff', fontWeight: 'normal'}}>A list of the
                            scheduled scenarios for this device.
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {scenariosLoading && <Container sx={{py: 5}}><Loading/></Container>}
                    {scenarios && scenarios.length > 0 &&
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'} sx={{fontWeight: 'bold'}}>House /
                                                Room</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 'bold'}}>Function</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 'bold'}}>Temperature</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 'bold'}}>Day(s)</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'} sx={{fontWeight: 'bold'}}>Turn
                                                on</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'} sx={{fontWeight: 'bold'}}>Turn
                                                off</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 'bold'}}>Delete</Typography></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    {scenarios && scenarios.map(scenario => (
                                        <TableBody key={scenario.tag}>
                                            <React.Fragment>
                                                <TableCell
                                                    align={'center'}><Typography>{scenario.housename} / {scenario.roomname}</Typography></TableCell>
                                                <TableCell
                                                    align={'center'}><Typography>{scenario.isheat === '1' ? 'Heating' : 'Cooling'}</Typography></TableCell>
                                                <TableCell
                                                    align={'center'}><Typography>{scenario.temp}°C</Typography></TableCell>
                                                <TableCell
                                                    align={'center'}><Typography>{scenario.days.join(', ')}</Typography></TableCell>
                                                <TableCell
                                                    align={'center'}><Typography>{scenario.starttime}</Typography></TableCell>
                                                <TableCell
                                                    align={'center'}><Typography>{scenario.endtime}</Typography></TableCell>
                                                <TableCell align={'center'}>
                                                    <Tooltip title="Delete">
                                                        <IconButton onClick={() => handleScenarioDelete(scenario.tag)}>
                                                            <DeleteOutlineIcon
                                                                sx={{color: red[500], fontSize: '25px'}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </React.Fragment>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                    {(!scenarios || scenarios.length < 1) && !scenariosLoading && <Alert severity="error" sx={{mt: 1}}>
                        <AlertTitle>No scheduled scenarios!</AlertTitle>
                        Schedule your device and come back to see the list of scheduled scenarios!
                    </Alert>}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{borderRadius: '5px', py: 0, mt: 2}} defaultExpanded={true} expanded={true}
                       disableGutters>
                <AccordionSummary className={'fancyBackground noCursorPointer'}
                                  sx={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px'}}
                                  aria-controls="panel2bh-content"
                                  id="panel2bh-header">
                    <Box>
                        <Typography variant="h6" sx={{color: '#fff'}}>
                            Add scenario
                        </Typography>
                        <Typography variant={'subtitle2'} sx={{color: '#fff', fontWeight: 'normal'}}>
                            Set a scenario for your device to turn ON/OFF on scheduled days and hours.
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{py: 2}}>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={2} display={'flex'} justifyContent={'center'}>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth required error={days.length === 0 && daysError}>
                                    <InputLabel id="demo-multiple-checkbox-label">Day(s)</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={days}
                                        onChange={handleDays}
                                        input={<OutlinedInput label="Day(s)"/>}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {dayList.map(day => (
                                            <MenuItem key={day} value={day}>
                                                <Checkbox checked={days.indexOf(day) > -1}/>
                                                <ListItemText primary={day}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl required fullWidth
                                             error={airConditionUsage === '' && airConditionUsageError}
                                >
                                    <InputLabel id="demo-simple-select-label">A/C Function</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={airConditionUsage}
                                        label="A/C Function"
                                        onChange={e => setAirConditionUsage(e.target.value)}
                                    >
                                        <MenuItem value='cool'>Cooling</MenuItem>
                                        <MenuItem value='heat'>Heating</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl required fullWidth error={temperature === '' && temperatureError}
                                >
                                    <InputLabel id="demo-simple-select-label">Temperature</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={temperature}
                                        label="Temperature"
                                        onChange={e => setTemperature(e.target.value)}
                                    >
                                        {temperatureList.map(temperature => (
                                            <MenuItem key={temperature} value={temperature}>{temperature}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl required={valueOFF === null} fullWidth
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Turn on at"
                                            value={valueON}
                                            onChange={handleChangeON}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl required={valueON === null} fullWidth
                                             error={valueOFFError && valueON === null}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Turn off at"
                                            value={valueOFF}
                                            onChange={handleChangeOFF}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {valueONError && valueOFFError &&
                            <Box mt={2}>
                                <CustomAlert severity="error">
                                    You must select at least one field from "Turn on at" and "Turn off at"!
                                </CustomAlert>
                            </Box>}

                        <Grid sx={{marginX: 'auto'}} item xs={12} md={2} display={'flex'} justifyContent={'center'}>
                            <Box sx={{mt: 5, mb: 2}}>
                                <Button type="submit" fullWidth variant="contained"
                                        color="middle">
                                    <Typography variant="small" sx={{color: 'white'}}>SCHEDULE</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <LoadingFullPage backDrop={backDrop} setBackDrop={setBackDrop}/>
            <Stack spacing={2} sx={{width: '100%'}}>
                {/* TODO handle close with content refresh */}
                <Snackbar open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
                    <CustomAlert onClose={() => setSuccess(false)} severity="success">
                        The scenario has been successfully scheduled!
                    </CustomAlert>
                </Snackbar>
                {/* TODO handle close with content refresh */}
                <Snackbar open={failure} autoHideDuration={3000} onClose={() => setFailure(false)}>
                    <CustomAlert onClose={() => setFailure(false)} severity="error">
                        Something went wrong! Please try again.
                    </CustomAlert>
                </Snackbar>
            </Stack>
        </Grid>
    );
}

export default AirConditionScheduling;