import React from 'react';
import {
    Avatar, Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Stack, TextField, Typography,
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";

import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import {green, red} from "@mui/material/colors";
import Loading from "../layout/Loading";

const ShellyController = ({
                              deviceName,
                              deviceHouseAddress,
                              valueON,
                              valueOFF,
                              onLoading,
                              on,
                              handleChangeTimeOn,
                              handleChangeTimeOFF,
                              handleChangeOnOff,
                              handleExecutionScheduling,
                              schedulingOnLoading,
                              schedulingOffLoading
                          }) => {
    return (
        <Card elevation={2}
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
              }}>
            <CardHeader className={'fancyBackground'}
                        avatar={<Avatar sx={{bgcolor: '#fff'}}><ElectricalServicesIcon
                            color={'primary'}/></Avatar>}
                        title={<Typography sx={{color: 'white'}}
                                           variant={'subtitle1'}>{deviceName}</Typography>}
                        subheader={<Typography sx={{color: 'white'}}
                                               variant={'subtitle2'}>{deviceHouseAddress}</Typography>}
            />
            <CardContent
                sx={{px: 0, display: 'flex', flexDirection: 'column'}}>

                <Grid container spacing={2} direction="row"
                      justifyContent="center"
                      alignItems="center" sx={{p: 1}}>
                    <Grid item md={4} xs={12}>
                        <Box sx={{mx: 'auto', my: 'auto', px: 'auto'}} display={'flex'}
                             justifyContent={'center'}>
                            {!onLoading ? <Box sx={{mx: 'auto', my: 'auto', px: 'auto'}} display={'flex'}
                                               justifyContent={'center'} flexDirection={'column'}>
                                <IconButton aria-label="delete" onClick={handleChangeOnOff}>
                                    <PowerSettingsNewIcon
                                        sx={{
                                            justifyContent: 'center',
                                            fontSize: '8rem',
                                            color: on ? green[700] : red[600]
                                        }}/>
                                </IconButton>
                                {on ? <Typography align={'center'} variant={'subtitle1'} fontWeight={'bold'} color={green[700]}>
                                    Currently Turned ON
                                </Typography> :
                                    <Typography align={'center'} variant={'subtitle1'} fontWeight={'bold'} color={red[600]}>
                                    Currently Turned OFF
                                    </Typography>}
                            </Box> : <Loading height={'8rem'}/>}
                        </Box>
                    </Grid>

                    <Grid item md={8} xs={12} display={'flex'}
                          justifyContent={'center'}>
                        <Stack spacing={3} direction="row">
                            <Stack spacing={1}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Turn on at"
                                        value={valueON}
                                        onChange={handleChangeTimeOn}Shelly
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <Button disabled={!valueON}
                                        variant="contained" onClick={() => handleExecutionScheduling('ON')}>
                                    {schedulingOnLoading ? <Loading color={'white'}/> :
                                        <Typography color={'white'}>SET</Typography>}
                                </Button>
                            </Stack>
                            {/*</Stack>*/}

                            {/*<Stack spacing={2} direction="row" mt={2}>*/}
                            <Stack spacing={1}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Turn off at"
                                        value={valueOFF}
                                        onChange={handleChangeTimeOFF}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <Button variant="contained" disabled={!valueOFF}
                                        onClick={() => handleExecutionScheduling('OFF')}>
                                    {schedulingOffLoading ? <Loading color={'white'}/> :
                                        <Typography color={'white'}>SET</Typography>}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>);
}

export default ShellyController;