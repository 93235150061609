import React from 'react';
import {Container, Divider, Grid, Typography} from "@mui/material";

const AirConditionLearningInstructions = ({scenario}) => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={6} justifyContent={'center'}
                  sx={{mt: 3, border: '2px solid #00ABE1', borderRadius: '5px'}}>
                <Typography align={'center'} variant="h5"
                            sx={{color: 'secondary.main', pb: 0}}>Instructions</Typography>
                <Divider sx={{backgroundColor: 'secondary.main', height: '2px', width: '100%', mt: 0}}/>
                <Container sx={{justifyContent: 'center', py: 2}}>
                    {scenario.func !== 'OFF' && <React.Fragment>
                        <Typography align={'center'}>Set the remote control to the status:</Typography>
                        <Typography variant={'h6'} align={'center'} sx={{my: 2}}>
                            <span style={{fontWeight: 'bold'}}>{scenario.func}</span>,
                            <span style={{fontWeight: 'bold'}}> temperature {scenario.temp}°C</span> and
                            <span style={{fontWeight: 'bold'}}> Fan on "Auto".</span>
                        </Typography>
                        <Typography align={'center'}>
                            Point it at the EHS Control and press the controller's ON button.
                        </Typography>
                    </React.Fragment>}
                    {scenario.func === 'OFF' &&
                    <React.Fragment>
                        <Typography variant={'h6'} align={'center'} sx={{my: 2}}>
                            Point it at the EHS Control and press the controller's OFF button.
                        </Typography>
                    </React.Fragment>}
                </Container>
            </Grid>
        </Grid>
    );
}

export default AirConditionLearningInstructions;