import React from 'react';
import {Alert} from '@mui/material';
import {Box} from "@mui/material";

const ErrorMessage = ({message}) => {
    return (
        <Box marginTop={2}>
            <Alert severity="error">{message}</Alert>
        </Box>
    );
}

export default ErrorMessage;