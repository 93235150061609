import {
    Route,
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import axios from "axios";

import SignUp from "./pages/auth/SignUp";
import LayoutUnAuth from "./components/layout/LayoutUnAuth";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={'/'} element={<LayoutUnAuth/>}>
            <Route path='/signup' element={<SignUp/>}/>
        </Route>
    ),
    // {basename: '/ehs'}
)

axios.defaults.baseURL = 'https://hedgeiot.epu.ntua.gr/api'
axios.defaults.headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
}

function AppUnAuth() {
    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
}

export default AppUnAuth;
