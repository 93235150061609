import React from 'react';
import {Box, Paper, Typography} from "@mui/material";

import {useTheme} from '@mui/material/styles';

const PageTitle = ({title}) => {
    const theme = useTheme();
    return (
        <div>
            <Paper variant="elevation" elevation={5}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: 60,
                        background: theme.palette.fancy.main,
                    }}
                >
                    <Box sx={{p: 2}}>
                        <Typography className="page-title" variant="h5" fontWeight="500"
                                    color="white">{title}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </div>
    );
}

export default PageTitle;