import axios from 'axios'

// Sign Up method
export const signUp = payload => {
    return new Promise(((resolve, reject) => {

        console.log(payload)

        axios.post('user/create', payload)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}