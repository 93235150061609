import axios from 'axios'

// Add new room
export const addRoom = room => {
    return new Promise(((resolve, reject) => {
        axios.post('room/create', room)
            .then(response => {
                resolve(response.data)
                console.log(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Get all the rooms
export const getAllRooms = () => {
    return new Promise(((resolve, reject) => {
        setTimeout(() => {
            axios.get('user/getallrooms')
                .then(response => {
                    resolve(response.data)
                    console.log(response.data)
                })
                .catch(error => reject(error))
        }, 2000)
    }))
}

// Get all the rooms of a specific house
export const getAllHouseRooms = houseId => {
    return new Promise(((resolve, reject) => {
        axios.get(`user/getallrooms/${houseId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Delete specific room
export const deleteRoomEntity = roomId => {
    return new Promise(((resolve, reject) => {
        axios.delete(`room/delete/${roomId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    }))
}

// Get all the appliances
export const getAllAppliances = houseId => {
    return new Promise(((resolve, reject) => {
        setTimeout(() => {
            axios.get(`/broadlinks/gethome/${houseId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        }, 2000)

    }))
}

// Add new AC controller
export const createNewAcController = controller => {
    return new Promise(((resolve, reject) => {
        axios.post('ac/create', controller)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Add new Shelly to room
export const addShellyToRoom = (roomId, shellyId) => {
    return new Promise(((resolve, reject) => {
        axios.post(`/shelly/add/room/${roomId}/${shellyId}`, null)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}