import React, { useState, useEffect, useCallback } from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {useNavigate} from "react-router-dom";

import { signUp } from "../../api_calls/api_calls_auth";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert, Snackbar } from "@mui/material";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Loading from "../../components/layout/Loading";
import ErrorMessage from "../../components/layout/ErrorMessage";

const SignUp = () => {
    useEffect(() => {
        document.title = 'Sign Up | Energy Home System';
    }, []);

    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated) {
                window.location.href = '/'
                setAllowed(false)
            } else {
                setAllowed(true)
            }
        }
    }, [keycloak, initialized]);

    const [formValues, setFormValues] = useState({
        username: '',
        password: '',
        email: '',
        firstName: '',
        lastName: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const validate = useCallback(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let newErrors = {};

        if (!formValues.username) newErrors.username = true;
        if (!formValues.password) newErrors.password = true;
        if (!formValues.email) newErrors.email = true;
        if (formValues.email && !emailRegex.test(formValues.email)) newErrors.emailInvalid = true;
        if (!formValues.firstName) newErrors.firstName = true;
        if (!formValues.lastName) newErrors.lastName = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formValues]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
        // Validate only the changed field
        validateField(name, value);
    };

    const validateField = (name, value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let newErrors = { ...errors };

        switch (name) {
            case 'username':
                newErrors.username = value ? '' : true;
                break;
            case 'password':
                newErrors.password = value ? '' : true;
                break;
            case 'email':
                newErrors.email = value ? '' : true;
                newErrors.emailInvalid = value && !emailRegex.test(value) ? true : '';
                break;
            case 'firstName':
                newErrors.firstName = value ? '' : true;
                break;
            case 'lastName':
                newErrors.lastName = value ? '' : true;
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (validate()) {
            handleSignUp();
        } else {
            setErrorMessage('Please fill in all the required fields.');
        }
    };

    const handleSignUp = async () => {
        setErrorMessage('');
        setLoading(true);

        try {
            await signUp(formValues);
            setLoading(false);
            setSignUpSuccess(true);
        } catch (error) {
            setLoading(false);
            setErrorMessage('Something went wrong! Please try again!');
            console.error(error);
        }
    };

    const handleCloseSnackbar = () => {
        setSignUpSuccess(false);
        window.location.replace("/");
    };

    return (
        <>
            <CssBaseline/>
            {allowed && <Box sx={{display: 'flex', flexDirection: 'row', height: '96vh', background: "#fefefe"}}>
                <Box
                    sx={{
                        width: '30%', // Adjusted width for the image
                        backgroundImage: `url(https://eu-images.contentstack.com/v3/assets/blt31d6b0704ba96e9d/blt88a7087d82cacbf3/655e23392ab1fb0407ec12a9/GettyImages-582763822.jpg?width=850&auto=webp&quality=95&format=jpg&disable=upscale)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: {xs: 'none', md: 'block'},
                        position: 'relative',
                        opacity: '90%'
                    }}
                >
                    {/*<Box*/}
                    {/*    sx={{*/}
                    {/*        position: 'absolute',*/}
                    {/*        top: '50%',*/}
                    {/*        left: '50%',*/}
                    {/*        transform: 'translate(-50%, -50%)',*/}
                    {/*        color: 'white',*/}
                    {/*        padding: 4,*/}
                    {/*        borderRadius: 8,*/}
                    {/*        textAlign: 'center',*/}
                    {/*        width: '90%',*/}
                    {/*        border: '1px solid white'*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Typography variant="h5" sx={{mb: 2}}>Save on Energy Costs</Typography>*/}

                    {/*    <Typography variant="h5" sx={{my: 2}}>Increase Energy Efficiency</Typography>*/}

                    {/*    <Typography variant="h5" sx={{mt: 2}}>Enhance Home Comfort</Typography>*/}
                    {/*</Box>*/}
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 4
                    }}
                >
                    <Avatar sx={{bgcolor: 'secondary.main', mx: 'auto'}} className={'fancyButton'}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{
                        mt: 1, width: '100%', display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        {['username', 'password', 'email', 'firstName', 'lastName'].map((field) => (
                            <TextField
                                key={field}
                                onChange={handleChange}
                                color="secondary"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id={field}
                                type={field === 'password' && !showPassword ? 'password' : 'text'}
                                label={field.charAt(0).toUpperCase() + field.slice(1)}
                                name={field}
                                autoComplete={field}
                                autoFocus={field === 'username'}
                                error={!!errors[field] || (field === 'email' && errors.emailInvalid)}
                                helperText={(errors[field] && field === 'email') ? errors[field] : ''}
                                sx={{width: '60%'}}
                                InputProps={field === 'password' ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                } : null}
                            />
                        ))}
                        <Box sx={{mt: 3, mb: 2, width: '60%'}}>
                            {loading && <Loading/>}
                            <Button
                                sx={{color: 'white', mt: 2}}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={'fancyButton'}
                            >
                                <Typography>SIGN UP</Typography>
                            </Button>
                        </Box>
                    </Box>

                    {errorMessage && <ErrorMessage message={errorMessage}/>}

                    <Snackbar open={signUpSuccess} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                        <Alert variant='filled' onClose={handleCloseSnackbar} severity="success">
                            The user has been successfully registered! You will be navigated to the Homepage.
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>}
        </>
    );
};

export default SignUp;
