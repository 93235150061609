import React from 'react';
import {Link} from "react-router-dom";

import {Button, Container, Grid, Paper, Typography} from "@mui/material";

const NoRooms = () => {
    return (
        <Container>
            <Paper sx={{p: 4, mt: 3}}>
                <Typography align={'center'} variant="h5">You have not added any rooms yet!</Typography>
                <Typography align={'center'} variant="subtitle1" sx={{mt: 2}}>Add your rooms to use the Energy Home
                    System!</Typography>
                <Grid container justifyContent='center'>
                    <Grid item md={4} sx={{mt: 3}}>
                        <Link to="/add/room" style={{textDecoration: 'none'}}>
                            <Button style={{color: 'white'}} type="submit" fullWidth variant="contained"
                                    color="secondary" onClick={null}>
                                <Typography>ADD ROOM</Typography>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default NoRooms;