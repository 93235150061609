import React, {useState, useEffect} from 'react';
import {Box, Grid, Paper, Typography, LinearProgress, Alert, AlertTitle, Skeleton} from "@mui/material";
import {useParams} from 'react-router-dom';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import TodayIcon from '@mui/icons-material/Today';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {getHouseLatestMetrics} from '../../../api_calls/api_calls_houses';

const HouseMonitoringLatestMetrics = () => {
    const {id} = useParams();

    const [metrics, setMetrics] = useState({
        lastHourConsumption: '',
        last24HoursConsumption: '',
        yesterdayConsumption: '',
        todayConsumption: '',
        monthlyConsumption: '' // Added monthly consumption for loading bar
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const monthlyAverage = 600; // Mocked monthly average (replace with dynamic if available)

    useEffect(() => {
        setLoading(true);
        setError(false);
        getHouseLatestMetrics(id)
            .then(response => {
                setMetrics({
                    lastHourConsumption: `${response.last_hour_consumption} kWh`,
                    last24HoursConsumption: `${response.last_24_hours_consumption} kWh`,
                    yesterdayConsumption: `${response.yesterdays_consumption} kWh`,
                    todayConsumption: `${response.todays_consumption} kWh`,
                    monthlyConsumption: response.monthly_consumption || 450 // Placeholder for current month
                });
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [id]);

    const monthPercentage = Math.min(100, ((metrics.monthlyConsumption || 0) / monthlyAverage) * 100);

    const boxStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '25px',
        width: '100%',
        gap: '10px',
    };

    return (
        <Grid container spacing={2}>
            {error ? (
                <Grid item xs={12}>
                    <Paper elevation={0} style={boxStyle}>
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong while fetching the data. Please try again later.
                        </Alert>
                    </Paper>
                </Grid>
            ) : (
                <>
                    {/* First 4 Boxes */}
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height="100%" sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <AccessAlarmIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.lastHourConsumption}</Typography>
                                    <Typography variant="subtitle2">Last hour consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={80} sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <EventAvailableIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.last24HoursConsumption}</Typography>
                                    <Typography variant="subtitle2">Last 24 hours consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={80} sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <TodayIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.yesterdayConsumption}</Typography>
                                    <Typography variant="subtitle2">Yesterday consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={80} sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <EventNoteIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.todayConsumption}</Typography>
                                    <Typography variant="subtitle2">Consumption today until now</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>

                    {/* Monthly Progress Loading Bar */}
                    <Grid item xs={12}>
                        <Paper
                            elevation={0}
                            style={{
                                padding: '20px',
                                borderRadius: '25px',
                                backgroundColor: '#fff',
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            {/* Section Title */}
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                                sx={{fontWeight: 'bold', mb: 3}}
                                className={'fancyText'}
                            >
                                Monthly Consumption Insight
                            </Typography>

                            {/* Linear Progress with Details */}
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {/* Linear Progress Bar */}
                                <LinearProgress
                                    variant="determinate"
                                    value={monthPercentage}
                                    sx={{
                                        width: '100%',
                                        height: 12,
                                        borderRadius: 6,
                                        backgroundColor: 'rgba(0, 171, 225, 0.2)', // Light background
                                        '& .MuiLinearProgress-bar': {
                                            backgroundImage: `linear-gradient(to right, #00ABE1, #161F6D)`,
                                        },
                                    }}
                                />
                                {/* Bolded Consumption Info */}
                                <Typography variant="body1" align="center" sx={{mt: 2}}>
                                    <strong>{metrics.monthlyConsumption} kWh</strong> out
                                    of <strong>{monthlyAverage} kWh</strong> (<strong>{monthPercentage.toFixed(1)}%</strong>)
                                </Typography>

                                {/* Subtitle with Info Icon */}
                                <Box display="flex" alignItems="center" gap="5px" mt={1}>
                                    <InfoOutlinedIcon sx={{color: '#00ABE1'}}/>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        align="center"
                                        sx={{fontSize: '0.75rem', lineHeight: '1.2'}}
                                    >
                                        Consumption this month compared to last months' average total consumption
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default HouseMonitoringLatestMetrics;
