import React, {useState} from 'react'
import {getAllAppliances} from "../../api_calls/api_calls_rooms";
import {styled} from '@mui/material/styles';
import {Link} from "react-router-dom";

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar'
import {Container, Divider, Grid, Snackbar, Stack, Tooltip, Alert} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WaterIcon from '@mui/icons-material/Water';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WaterDamageSharpIcon from '@mui/icons-material/WaterDamageSharp';
import DeviceThermostatSharpIcon from '@mui/icons-material/DeviceThermostatSharp';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DeleteIcon from '@mui/icons-material/Delete';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import HouseIcon from '@mui/icons-material/House';
import DevicesIcon from '@mui/icons-material/Devices';

import {blue, green, red, yellow} from "@mui/material/colors";
import ControllersList from "./ControllersList";
import {broadlinkDeleteFromRoom} from "../../api_calls/api_calls_broadlinks";
import {shellyDeleteFromRoom} from "../../api_calls/api_calls_shelly";


const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton sx={{backgroundColor: 'none'}} {...other} />;
})(({theme, expand}) => ({
    backgroundColor: 'none',
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    })
}));

const style = {
    position: 'relative',
    top: '30%',
    width: '100%',
    maxWidth: '500px',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 5,
    p: '0 !important',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    display: 'flex'
};

const defineAvatarBgColor = room => {
    if (room.roomusage === 'livingroom') {
        return blue[700]
    }
    if (room.roomusage === 'bedroom') {
        return green[500]
    }
    if (room.roomusage === 'office') {
        return yellow[500]
    }
    if (room.roomusage === 'other') {
        return red[300]
    }
    return red[500]
}

const RoomCard = ({room,
                      roomAppliances,
                      deleteRoom,
                      sensorData,
                      houseId
}) => {
    const [expanded, setExpanded] = React.useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [broadlinks, setBroadlinks] = useState([])
    const [shellys, setShellys] = useState([])
    const [appliancesLoading, setAppliancesLoading] = useState(false)

    // Variable for snackbar
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)

    const handleOpenModal = () => {
        getAppliances()
        setOpenModal(true)
    };

    const handleCloseModal = () => setOpenModal(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getAppliances = () => {
        setAppliancesLoading(true)
        getAllAppliances(houseId)
            .then(response => {
                setAppliancesLoading(false)
                setBroadlinks(response.broadlinks)
                setShellys(response.shellys)
            })
            .catch(error => {
                console.info(error)
                setAppliancesLoading(false)
            })
    }

    const handleDeleteBroadlink = id => {
        setFailure(false)
        broadlinkDeleteFromRoom(id)
            .then(response => {
                window.location.reload(false);
            })
            .catch(error => {
                setFailure(true)
                console.info(error)
            })
    }

    const handleDeleteShelly = id => {
        setFailure(false)
        shellyDeleteFromRoom(id)
            .then(response => {
                window.location.reload(false);
            })
            .catch(error => {
                setFailure(true)
                console.info(error)
            })
    }

    return (
        <div>
            <Modal keepMounted open={openModal} onClose={handleCloseModal} aria-labelledby="keep-mounted-modal-title"
                   aria-describedby="keep-mounted-modal-description" sx={{maxWidth: '500px', mx: 'auto'}}>
                <Container sx={style}>
                    {appliancesLoading ? <CircularProgress/> :
                        <ControllersList broadlinks={broadlinks?.length > 0 ? broadlinks : []}
                                         shellys={shellys?.length > 0 ? shellys : []} roomId={room.id}
                                         setSuccess={setSuccess}
                                         setFailure={setFailure} setOpenModal={setOpenModal}/>}
                </Container>
            </Modal>
            <Card raised>
                <CardHeader
                    avatar={
                        <Avatar sx={{bgcolor: defineAvatarBgColor(room)}}>
                            <MeetingRoomIcon/>
                        </Avatar>}
                    action={
                        <Tooltip title="Delete">
                            <IconButton onClick={() => deleteRoom(room.id)}>
                                <DeleteOutlineIcon sx={{color: red[500]}}/>
                            </IconButton>
                        </Tooltip>}
                    title={<Typography variant="subtitle1">{room.name}</Typography>}
                    subheader={'room.building.address'}
                />
                <Divider/>
                <CardContent sx={{px: {xs: 0, md: 2}}}>
                    <Grid container alignContent={'center'} justifyContent={'center'} spacing={2}>
                        <Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <PhotoSizeSelectSmallIcon className={'roomCardInfoIcon roomCardArea'}/>
                            <Typography variant={'body1'} sx={{mx: 'auto', color: '#777', fontWeight: 500}}>
                                Total area
                            </Typography>
                            <Typography align={'center'} variant={'h6'}
                                        sx={{mx: 'auto'}}>{room.area} m²</Typography>
                        </Grid>
                        <Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <HouseIcon className={'roomCardInfoIcon roomCardWindows'}/>
                            <Typography variant={'body1'} align={'center'}
                                        sx={{mx: 'auto', color: '#777', fontWeight: 500}}>
                                Windows
                            </Typography>
                            <Typography align={'center'} variant={'h6'}
                                        sx={{mx: 'auto'}}>{room.windows}</Typography>
                        </Grid>
                        <Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <DevicesIcon className={'roomCardInfoIcon roomCardDevices'}/>
                            <Typography variant={'body1'} align={'center'}
                                        sx={{mx: 'auto', color: '#777', fontWeight: 500}}>
                                Devices
                            </Typography>
                            {/*<Typography align={'center'} variant={'h6'}*/}
                            {/*            sx={{mx: 'auto'}}>{roomAppliances.broadlinks.length + roomAppliances.shellys.length}</Typography>*/}
                            <Typography align={'center'} variant={'h6'}
                                        sx={{mx: 'auto'}}>{'-'}</Typography>
                        </Grid>
                        {/*<Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>*/}
                        {/*    <DeviceThermostatSharpIcon className={'roomCardInfoIcon roomCardTemperature'}/>*/}
                        {/*    <Typography variant={'body1'} sx={{mx: 'auto', color: '#777', fontWeight: 500}}>*/}
                        {/*        Temperature*/}
                        {/*    </Typography>*/}
                        {/*    <Typography align={'center'} variant={'h6'}*/}
                        {/*                sx={{mx: 'auto'}}>{sensorData.temp === 0 ? '-' : sensorData.temp} °C</Typography>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={4} md={4}*/}
                        {/*      sx={{display: 'flex', flexDirection: 'column'}}>*/}
                        {/*    <WaterDamageSharpIcon className={'roomCardInfoIcon roomCardHumidity'}/>*/}
                        {/*    <Typography align={'center'} variant={'body1'}*/}
                        {/*                sx={{color: '#777', fontWeight: 500, width: '100%'}}>*/}
                        {/*        Humidity*/}
                        {/*    </Typography>*/}
                        {/*    <Typography align={'center'} variant={'h6'}*/}
                        {/*                sx={{mx: 'auto'}}>{sensorData.hum === 0 ? '-' : sensorData.hum} %</Typography>*/}
                        {/*</Grid>*/}
                    </Grid>

                    {/*<Typography variant="body1" color="textmiddle" sx={{mt: 2}}>*/}
                    {/*    This room*/}
                    {/*    has {roomAppliances.broadlinks.length + roomAppliances.shellys.length}&nbsp;controller{(roomAppliances.broadlinks.length + roomAppliances.shellys.length) === 1 ? null : 's'}.*/}
                    {/*</Typography>*/}
                </CardContent>
                <CardActions disableSpacing>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="Room's devices"
                        sx={{backgroundColor: 'middle.main', ":hover": {backgroundColor: 'middle.main'}}}
                    >
                        <Tooltip title="Room's Controllers">
                            <ExpandMoreIcon sx={{color: 'white', bgcolor: 'middle.main', borderRadius: '50%'}}/>
                        </Tooltip>
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Box sx={{width: '100%'}}>
                            <List>
                                <ListItem disablePadding onClick={handleOpenModal}>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} display={'flex'} alignItems="center" justifyContent="center">
                                            <ListItemButton alignItems={'center'} sx={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                justifyItems: 'center'
                                            }} disableGutters>
                                                <ListItemIcon>
                                                    <AddCircleIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary='Add controller' sx={{mb: 1, mt: '9px'}}/>
                                            </ListItemButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider sx={{mb: 1}}/>
                                {/*{roomAppliances && roomAppliances.broadlinks.map(appliance =>*/}
                                {/*    <React.Fragment key={appliance.broadlinkId}>*/}
                                {/*        <ListItem disablePadding>*/}
                                {/*            <Grid container>*/}
                                {/*                <Grid item xs={7} sx={{display: 'flex'}}*/}
                                {/*                      alignItems="center" justifyContent="center">*/}
                                {/*                    <ListItemIcon>*/}
                                {/*                        /!* TODO Specify types and according icons *!/*/}
                                {/*                        {appliance.brandType === 'brandType' ? <AcUnitIcon/>*/}
                                {/*                            : appliance.brandType === 'Boiler' ? <WaterIcon/> :*/}
                                {/*                                <ConnectedTvIcon/>}*/}
                                {/*                    </ListItemIcon>*/}
                                {/*                    <ListItemText primary={appliance.name} sx={{display: 'flex'}}/>*/}
                                {/*                </Grid>*/}
                                {/*                <Grid item xs={2} sx={{display: 'flex', my: 1, ml: 'auto'}}*/}
                                {/*                      alignItems="center"*/}
                                {/*                      justifyContent="center">*/}
                                {/*                </Grid>*/}
                                {/*                <Grid item xs={1} sx={{display: 'flex', my: 1}} alignItems="center"*/}
                                {/*                      justifyContent="center">*/}
                                {/*                    <Tooltip title={'Learning'} placement={'top'}>*/}
                                {/*                        <Link*/}
                                {/*                            to={`/controller/aircondition/learning/${appliance.accontrollerid}`}>*/}
                                {/*                            <ListItemIcon sx={{justifyContent: 'center'}}>*/}
                                {/*                                <SettingsRemoteIcon/>*/}
                                {/*                            </ListItemIcon>*/}
                                {/*                        </Link>*/}
                                {/*                    </Tooltip>*/}
                                {/*                </Grid>*/}
                                {/*                <Grid item xs={1} sx={{display: 'flex', my: 1}} alignItems="center"*/}
                                {/*                      justifyContent="center">*/}
                                {/*                    <Tooltip title={'Control'} placement={'top'}>*/}
                                {/*                        <Link*/}
                                {/*                            to={`/controller/aircondition/handling/${appliance.accontrollerid}`}>*/}
                                {/*                            <ListItemIcon sx={{justifyContent: 'center'}}>*/}
                                {/*                                <SendToMobileIcon/>*/}
                                {/*                            </ListItemIcon>*/}
                                {/*                        </Link>*/}
                                {/*                    </Tooltip>*/}
                                {/*                </Grid>*/}
                                {/*                <Grid item xs={1} sx={{display: 'flex', my: 1, ml: 'auto'}}*/}
                                {/*                      alignItems="center"*/}
                                {/*                      justifyContent="center">*/}
                                {/*                    <Tooltip title={'Delete'} placement={'top'}>*/}
                                {/*                        <Link to={''} onClick={e => e.preventDefault()}>*/}
                                {/*                            <ListItemIcon sx={{justifyContent: 'center'}}*/}
                                {/*                                          onClick={() => handleDeleteBroadlink(appliance.accontrollerid)}>*/}
                                {/*                                <DeleteIcon/>*/}
                                {/*                            </ListItemIcon>*/}
                                {/*                        </Link>*/}
                                {/*                    </Tooltip>*/}
                                {/*                </Grid>*/}
                                {/*            </Grid>*/}
                                {/*        </ListItem>*/}
                                {/*    </React.Fragment>)}*/}
                                {/*{roomAppliances && roomAppliances.shellys.map(appliance =>*/}
                                {/*    <React.Fragment key={appliance.id}>*/}
                                {/*        <ListItem disablePadding>*/}
                                {/*            <Grid container>*/}
                                {/*                <Grid item xs={7} sx={{display: 'flex', flexGrow: '1'}}*/}
                                {/*                      alignItems="center" justifyContent="center">*/}
                                {/*                    <ListItemIcon>*/}
                                {/*                        /!* TODO Specify icons based on type of device *!/*/}
                                {/*                        <ConnectedTvIcon/>*/}
                                {/*                    </ListItemIcon>*/}
                                {/*                    <ListItemText primary={appliance.name}*/}
                                {/*                                  sx={{display: 'flex', flexGrow: '1'}}/>*/}
                                {/*                </Grid>*/}
                                {/*                <Grid item xs={3} sx={{display: 'flex', my: 1, ml: 'auto'}}*/}
                                {/*                      alignItems="center"*/}
                                {/*                      justifyContent="center">*/}
                                {/*                </Grid>*/}
                                {/*                <Grid item xs={1} sx={{display: 'flex', my: 1, ml: 'auto'}}*/}
                                {/*                      alignItems="center"*/}
                                {/*                      justifyContent="center">*/}
                                {/*                    <Tooltip title={'Control'} placement={'top'}>*/}
                                {/*                        <Link*/}
                                {/*                            to={`/controller/shelly/handling/${appliance.id}`}>*/}
                                {/*                            <ListItemIcon sx={{justifyContent: 'center'}}>*/}
                                {/*                                <SendToMobileIcon/>*/}
                                {/*                            </ListItemIcon>*/}
                                {/*                        </Link>*/}
                                {/*                    </Tooltip>*/}
                                {/*                </Grid>*/}
                                {/*                <Grid item xs={1} sx={{display: 'flex', my: 1, ml: 'auto'}}*/}
                                {/*                      alignItems="center"*/}
                                {/*                      justifyContent="center">*/}
                                {/*                    <Tooltip title={'Delete'} placement={'top'}>*/}
                                {/*                        <Link to={''} onClick={e => e.preventDefault()}>*/}
                                {/*                            <ListItemIcon sx={{justifyContent: 'center'}}*/}
                                {/*                                          onClick={() => handleDeleteShelly(appliance.id)}>*/}
                                {/*                                <DeleteIcon/>*/}
                                {/*                            </ListItemIcon>*/}
                                {/*                        </Link>*/}
                                {/*                    </Tooltip>*/}
                                {/*                </Grid>*/}
                                {/*            </Grid>*/}
                                {/*        </ListItem>*/}
                                {/*    </React.Fragment>)}*/}
                            </List>
                        </Box>
                    </CardContent>
                </Collapse>
            </Card>
            <Stack spacing={2} sx={{width: '100%'}}>
                <Snackbar open={success} autoHideDuration={2000} onClose={() => window.location.reload(false)}>
                    <Alert onClose={() => window.location.reload(false)} severity="success">
                        The controller has been successfully added!
                    </Alert>
                </Snackbar>
                <Snackbar open={failure} autoHideDuration={2000} onClose={() => setFailure(false)}>
                    <Alert onClose={() => setFailure(false)} severity="error">
                        Something went wrong! Please try again.
                    </Alert>
                </Snackbar>
            </Stack>
        </div>
    )
}

export default RoomCard
