import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useParams, useNavigate } from "react-router-dom";

function HouseMonitoringWelcomeMsg({ houseAddress }) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const { id } = useParams(); // Get house ID from route parameters
    const navigate = useNavigate(); // Hook to navigate programmatically

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, []);

    const currentDate = currentTime.toLocaleDateString();
    const currentLiveTime = currentTime.toLocaleTimeString();

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: 'rgba(10, 107, 172, 0.2)',
        borderRadius: '25px',
        width: '100%'
    };

    const handleEditClick = () => {
        navigate(`/edit/house/${id}`); // Navigate to edit house details page
    };

    return (
        <>
            <Paper
                elevation={0}
                style={{
                    padding: '20px',
                    borderRadius: '25px',
                    backgroundColor: 'white',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="h4">Welcome home!</Typography>
                <Typography variant="subtitle2" gutterBottom mb={2}>
                    {houseAddress}
                </Typography>

                {/* Edit House Details Button */}
                <Button className={'fancyButton'}
                    variant="contained"
                    onClick={handleEditClick}
                    startIcon={<EditNoteIcon />} // Add icon at the start
                    sx={{ mb: 2, width: 'fit-content' }}
                >
                    Edit house details
                </Button>

                <Grid container spacing={2} sx={{ mt: 'auto' }}>
                    <Grid item xs={12}>
                        <Box style={boxStyle}>
                            <Typography variant="subtitle2">Date</Typography>
                            <Box display="flex" alignItems="center" gap="5px">
                                <CalendarTodayIcon />
                                <Typography>{currentDate}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={boxStyle}>
                            <Typography variant="subtitle2">Time</Typography>
                            <Box display="flex" alignItems="center" gap="5px">
                                <AccessTimeIcon />
                                <Typography>{currentLiveTime}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default HouseMonitoringWelcomeMsg;
