import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
import {
    shellyGetCurrentStatus,
    shellyToggleOnOff,
    shellyScheduling,
    shellyGetEntityScenarios
} from "../../api_calls/api_calls_appliance";

import {Grid, Container} from "@mui/material";

import PageTitle from "../../components/layout/PageTitle";
import LoadingFullPage from "../../components/layout/LoadingFullPage";
import ShellyScheduling from "../../components/appliances/ShellyScheduling";
import ShellyController from "../../components/appliances/ShellyController";
import ShellyCharts from "../../components/appliances/ShellyCharts";

const ShellyHandling = () => {
    let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE3MTg4ODE4NDksInVzZXJfaWQiOiI1OTJhN2YxMC04OTM4LTRmZDktYTRiNy0xOGVkMDBhMjNkMmUifQ.55DOvq7ZyUqe_t5ButOfCzXjqLkUTwTHEo6xFnbdUMw'

    const {id} = useParams();
    const [loading, setLoading] = useState(false)

    const [on, setOn] = useState(false)
    const [onLoading, setOnLoading] = useState(false)
    const [deviceName, setDeviceName] = useState('')
    const [deviceHouseAddress, setDeviceHouseAddress] = useState('')
    const [valueON, setValueON] = useState(null);
    const [valueOFF, setValueOFF] = useState(null);
    const [schedulingOnLoading, setSchedulingOnLoading] = useState(false)
    const [schedulingOffLoading, setSchedulingOffLoading] = useState(false)

    // Reusable function to fetch shelly's current status
    const fetchShellyCurrentStatus = () => {
        setLoading(true)
        shellyGetCurrentStatus(id)
            .then(response => {
                setDeviceName(response.name)
                setDeviceHouseAddress(response.address)
                setOn(response.status === 'on')
                setLoading(false)
            })
            .catch(error => {
                setOn(false)
                setLoading(false)
            })
    }

    // Get shelly's current status on first load
    useEffect(() => {
        fetchShellyCurrentStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Function for handling ON/OFF execution
    const handleChangeOnOff = () => {
        const flag = on ? 'off' : 'on'
        setOnLoading(true)

        // TODO Check functionality when API response is setup
        shellyToggleOnOff(id, flag)
            .then(response => {
                setOn(response.data.status === 'on')
                setOnLoading(false)
            })
            .catch(err => {
                setOnLoading(false)
            })
    }

    const handleChangeTimeOn = newValue => {
        setValueON(newValue);
    };

    const handleChangeTimeOFF = newValue => {
        setValueOFF(newValue);
    };

    const [scenarios, setScenarios] = useState([])
    const [scenariosLoading, setScenariosLoading] = useState(false)

    const getShellyScenarios = () => {
        setScenariosLoading(true)
        shellyGetEntityScenarios(id)
            .then(response => {
                response === null ? setScenarios([]) : setScenarios(response)
                setScenariosLoading(false)
            })
            .catch(error => {
                setScenarios([])
                setScenariosLoading(false)
            })
    }

    const handleExecutionScheduling = exec_function => {
        exec_function === 'ON' ? setSchedulingOnLoading(true) : setSchedulingOffLoading(true)
        let payload = {
            id,
            desc: 'once',
            func: exec_function,
            starttime: valueON,
            endtime: valueOFF,
            typeofdevice: 'shelly',
        }


        shellyScheduling(payload)
            .then(response => {
                getShellyScenarios()
                exec_function === 'ON' ? setSchedulingOnLoading(false) : setSchedulingOffLoading(false)
                exec_function === 'ON' ? setValueON(null) : setValueOFF(null)
            })
            .catch(error => {
                setTimeout(() => {  // TODO Delete when functionality is provided by REST API
                    exec_function === 'ON' ? setValueON(null) : setValueOFF(null)
                    exec_function === 'ON' ? setSchedulingOnLoading(false) : setSchedulingOffLoading(false)
                }, 2000) // TODO Delete when functionality is provided by REST API
                console.log(error)
            })
    }

    return (
        <React.Fragment>
            {deviceName && <PageTitle title={deviceName + ' Handling & Scheduling'}/>}
            <Container maxWidth={'row'}>
                <Grid container mt={0} px={2} direction="row" spacing={2}>
                    <Grid item xs={12} md={4}>
                        <ShellyController
                            deviceName={deviceName}
                            deviceHouseAddress={deviceHouseAddress}
                            valueON={valueON}
                            valueOFF={valueOFF}
                            onLoading={onLoading}
                            on={on}
                            handleChangeTimeOn={handleChangeTimeOn}
                            handleChangeTimeOFF={handleChangeTimeOFF}
                            handleChangeOnOff={handleChangeOnOff}
                            handleExecutionScheduling={handleExecutionScheduling}
                            schedulingOnLoading={schedulingOnLoading}
                            schedulingOffLoading={schedulingOffLoading}
                        />

                        <ShellyScheduling scenarios={scenarios} setScenarios={setScenarios}
                                          scenariosLoading={scenariosLoading} setScenariosLoading={setScenariosLoading}
                                          getShellyScenarios={getShellyScenarios}
                        />
                    </Grid>


                    <Grid item md={8} xs={12}>
                        <ShellyCharts shellyId={id} token={token}/>
                    </Grid>

                </Grid>
            </Container>
            <LoadingFullPage backDrop={loading}/>

        </React.Fragment>
    );
}

export default ShellyHandling;