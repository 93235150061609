import React from 'react';

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const LoadingFullPage = ({backDrop}) => {
    return (
        <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={backDrop}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
    );
}

export default LoadingFullPage;